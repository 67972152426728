<template>
  <EmailLoader>
    <p>This is just a friendly reminder that your subscription will renew automatically on Friday 31st, December 2021.</p>

    <p>You can manage your subscription by simply clicking on the button below!</p>

    <div class="text-center">
      <b-button variant="primary">
        Manage Subscription
      </b-button>
    </div>
  </EmailLoader>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import EmailLoader from '../setup/loader.vue'

export default {
  components: {
    BButton,
    EmailLoader,
  },
}
</script>
